import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Ponuda.scss';

const Ponuda = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, []);

  // Fetch products from the API
  const fetchProducts = async () => {
    try {
      const response = await fetch('https://api.gilje.shop/products');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setProducts(data);
      } else {
        console.error('Expected an array of products');
      }
    } catch (error) {
      console.error('Error fetching products from API', error);
    }
  };

  // Handle click on "Add to Cart" button
  const handleAddToCart = (product) => {
    // Retrieve existing cart from localStorage
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    
    // Check if the product is already in the cart
    const existingProductIndex = cart.findIndex(item => item.id === product.id);
    
    if (existingProductIndex >= 0) {
      // Update quantity if product already exists in cart
      cart[existingProductIndex].quantity += 1;
    } else {
      // Add new product to the cart
      cart.push({...product, quantity: 1});
    }
    
    // Save updated cart to localStorage
    localStorage.setItem('cart', JSON.stringify(cart));
    
    // Redirect to the cart page
    navigate('/card');
  };

  return (
    <div id="ponuda" className="ponuda-container">
      <h2>Naša Ponuda</h2>
      <div className="products-container">
        {products.length === 0 ? (
          <p>Nema dostupnih proizvoda.</p>
        ) : (
          <div className="products-list">
            {products.map((product) => (
              <div key={product.id} className="product-item">
                <h3>{product.naziv}</h3>
                {product.image && (
                  <img
                    src={product.image}
                    alt={product.naziv}
                    className="product-image"
                  />
                )}
                <p><strong>Cena:</strong> {product.cena}</p>
                <p><strong>Specifikacija:</strong> {product.specifikacija}</p>
                <p><strong>Opis:</strong> {product.opis}</p>
                <button
                  onClick={() => handleAddToCart(product)}
                  className="add-to-cart-button"
                >
                  Poruči
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Ponuda;
