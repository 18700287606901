import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './AdminPanel.scss'; // Import SCSS

const AdminPanel = () => {
  return (
    <div className="admin-container">
      <nav className="navbar">
        <h1>Admin Panel</h1>
        <p>Dobrodošli u admin panel!</p>
        <div className="button-group">
          <Link to="products" className="button-link">Proizvodi</Link>
          <Link to="orders" className="button-link">Porudžbine</Link>
          <Link to="/login" className="button-link">Odjava</Link>
        </div>
      </nav>
      <main className="content">
        <Outlet /> {/* Render nested routes here */}
      </main>
    </div>
  );
};

export default AdminPanel;
    