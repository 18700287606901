import React, { useState, useEffect } from "react";
import Navbar from '../../main/Navbar';
import Footer from '../../main/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./Card.scss";

const Card = () => {
  const [orderDetails, setOrderDetails] = useState({
    ime: '',
    prezime: '',
    grad: '',
    adresa: '',
    telefon: '',
    email: '',
    userId: 1,
    cart: []
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCart = async () => {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      setOrderDetails(prevState => ({
        ...prevState,
        cart
      }));
    };

    fetchCart();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const updateCartCount = async () => {
    try {
      const response = await axios.get('https://api.gilje.shop/cart/count', {
        params: { user_id: orderDetails.userId }
      });
      // Handle the response here
    } catch (error) {
      console.error('Error fetching cart count from server', error);
      setError('Error fetching cart count. Please try again later.');
    }
  };

  const handleOrderSubmit = async () => {
    if (!orderDetails.ime || !orderDetails.prezime || !orderDetails.grad || !orderDetails.adresa || !orderDetails.telefon || !orderDetails.email) {
      setError('Please fill in all fields.');
      return;
    }
  
    if (!/\S+@\S+\.\S+/.test(orderDetails.email)) {
      setError('Please enter a valid email address.');
      return;
    }
  
    if (orderDetails.cart.length > 5) {
      setError('You cannot submit an order with more than 5 products.');
      return;
    }
  
    try {
      const response = await axios.post('https://api.gilje.shop/orders', {
        user_id: orderDetails.userId,
        cartItems: orderDetails.cart.map(item => ({
          product_id: item.id,
          naziv: item.naziv,
          cena: item.cena,
          quantity: item.quantity
        })),
        first_name: orderDetails.ime,
        last_name: orderDetails.prezime,
        city: orderDetails.grad,
        address_line1: orderDetails.adresa,
        phone_number: orderDetails.telefon,
        email: orderDetails.email
      });
  
      localStorage.removeItem('cart');
      setOrderDetails(prevState => ({ ...prevState, cart: [] }));
  
      setShowConfirmation(true);
      setTimeout(() => {
        navigate('/');
      }, 1500);
    } catch (error) {
      console.error('Error submitting order:', error.response ? error.response.data : error.message);
      setError('Error submitting order. Please try again later.');
    }
  };

  const handleCancel = () => {
    localStorage.removeItem('cart');
    setOrderDetails(prevState => ({ ...prevState, cart: [] }));
    navigate('/');
  };

  const cartItemCount = orderDetails.cart.length;

  return (
    <div className="card-container">
      <Navbar />
      <div className="content-wrapper">
        <main>
          <h1>Order</h1>
          <h2>Order Details</h2>

          {error && <p className="error-message">{error}</p>}
          
          <form className="order-form">
            <label>
              First Name:
              <input
                type="text"
                name="ime"
                value={orderDetails.ime}
                onChange={handleInputChange}
                placeholder="Enter your first name"
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                name="prezime"
                value={orderDetails.prezime}
                onChange={handleInputChange}
                placeholder="Enter your last name"
              />
            </label>
            <label>
              City:
              <input
                type="text"
                name="grad"
                value={orderDetails.grad}
                onChange={handleInputChange}
                placeholder="Enter your city"
              />
            </label>
            <label>
              Address:
              <input
                type="text"
                name="adresa"
                value={orderDetails.adresa}
                onChange={handleInputChange}
                placeholder="Enter your address"
              />
            </label>
            <label>
              Phone Number:
              <input
                type="text"
                name="telefon"
                value={orderDetails.telefon}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={orderDetails.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
              />
            </label>
          </form>

          <h3>Items in Cart: {cartItemCount}</h3>
          <div className="cart-items">
            {orderDetails.cart.length === 0 ? (
              <p>No items in the cart.</p>
            ) : (
              orderDetails.cart.map((item, index) => (
                <div key={index} className="cart-item-card">
                  <h4>{item.naziv}</h4>
                  <p>{item.cena}</p>
                </div>
              ))
            )}
          </div>

          {showConfirmation && (
            <p className="confirmation-message">
              Your order has been placed, and you will be contacted as soon as possible.
            </p>
          )}

          <button onClick={handleOrderSubmit} className="order-button">
            Place Order
          </button>
          <button onClick={handleCancel} className="cancel-button">
            Cancel
          </button>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Card;
