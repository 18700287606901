import React from 'react';
import './About.scss'; // Import SCSS

const About = () => {
  return (
    <div id="about" className="about-container">
      <div className="about-header">
        <h1>O Nama</h1>
        <p>Upoznajte se bolje sa nama.</p>
      </div>

      <div className="about-content">
        <p>
          GILJE je ekskluzivna destinacija za ljubitelje obuće koja unosi svetske modne trendove direktno iz najpoznatijih metropola. Naša prodavnica predstavlja savršeno mesto za sve koji žele da istaknu svoj stil kroz pažljivo odabrane modele obuće.
        </p>
        <p>
          U GILJE-u, spoj urbane kulture i savremenog dizajna dolazi do izražaja kroz bogat asortiman koji zadovoljava najrazličitije ukuse. Bilo da tražite sportske patike za dinamičan stil života ili casual modele za svakodnevno nošenje, kod nas ćete pronaći savršeni par koji odgovara vašoj viziji.
        </p>
        <p>
          Naš jedinstven pristup prezentaciji proizvoda pruža vrhunsko iskustvo svakom posetiocu, dok inovativni dizajn kolekcija naglašava udobnost i funkcionalnost bez kompromisa sa stilom.
        </p>
        <p>
          GILJE nije samo prodavnica, već pravo središte modernih trendova obuće, gde svaki ljubitelj patika može pronaći najnovije hit modele. Posetite nas i budite deo modne revolucije u Crnoj Gori.
        </p>
      </div>
    </div>
  );
};

export default About;
