import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? "show-mobile-menu" : ""}`}>
      <div className="container">
      <div className="logo">
        <Link to="/login">
          <img src="./logo512.png" alt="Logo" />
        </Link>
        </div>

        <div className={`nav-links ${!isOpen ? "show-nav-links" : ""}`}>
          <Link to="/">Pocetna</Link>
          <a href="#ponuda">Ponuda</a>
          <a href="#about">O Nama</a>
          <a href="#footer">Kontakt</a>
        </div>

        <div className="mobile-menu-icon">
          <button onClick={toggleMenu}>
            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="mobile-menu">
          <Link to="/">Pocetna</Link>
          <a href="#ponuda">Ponuda</a>
          <a href="#about">O Nama</a>
          <a href="#footer">Kontakt</a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
