import React from "react";
import "./Footer.scss";
import { FaFacebookF, FaInstagram, FaTiktok, FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">

        </div>

        <div id="footer" className="footer-social">
          <a href="" target="https://www.facebook.com/profile.php?id=61564942487509" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="" target="https://www.instagram.com/gilje.cg" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="" target="https://www.tiktok.com/@gilje.cg" rel="noopener noreferrer">
            <FaTiktok />
          </a>  
        </div>
      </div>

      <div className="footer-rights">
        <p>© 2024 GILJE.SHOP All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;