import React from 'react';
import ModalImage from 'react-modal-image';
import './Galerija.scss'; // Import SCSS

const images = [
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  'https://via.placeholder.com/800x600',
  // Add more image URLs as needed
];

const Gallery = () => {
  return (
    <section className="gallery-section">
      <div className="gallery-header">
        <h1>Galerija</h1>
        <h2>Pregledajte našu kolekciju</h2>
      </div>
      <div className="gallery-container">
        {images.map((src, index) => (
          <div key={index} className="gallery-item">
            <ModalImage
              small={src}
              large={src}
              alt={`Gallery item ${index + 1}`}
              className="gallery-image"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
