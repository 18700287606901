// src/App.js
import './App.css'; // Ensure your global styles are imported
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home'; // Default export
import LoginForm from './components/panel/Login'; // Default export
import AdminPanel from './components/panel/AdminPanel'; // Default export
import Products from './components/panel/admindata/Products'; // Updated import
import Orders from './components/panel/admindata/Orders'; // Updated import
import Ponuda from './components/Proizvodi/Ponuda'; // Correct casing
import Card from './components/Proizvodi/korpa/Card'; // Ensure correct casing

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the Home page */}
        <Route path="/" element={<Home />} />
        
        {/* Route for the Login page */}
        <Route path="/login" element={<LoginForm />} />
        
        {/* Admin Panel with nested routes */}
        <Route path="/admin-panel" element={<AdminPanel />}>
          <Route path="products" element={<Products />} />
          <Route path="orders" element={<Orders />} />
        </Route>
        
        {/* Route for the Ponuda component */}
        <Route path="/ponuda" element={<Ponuda />} />
        
        {/* Route for the Card component */}
        <Route path="/card" element={<Card />} />
      </Routes>
    </Router>
  );
}

export default App;
