// src/components/login/LoginForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.scss'; // Uvezi SCSS

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (username === 'admin' && password === 'admin') {
      localStorage.setItem('authenticated', 'true');
      navigate('/admin-panel');
    } else {
      alert('Pogrešan korisnički naziv ili lozinka');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Korisničko ime:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Lozinka:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Prijavi se</button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
