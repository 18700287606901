/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Navbar from '../main/Navbar';
import Footer from '../main/Footer';
import Ponuda from '../Proizvodi/Ponuda'; // Correct casing
import About from '../about/About';
import Gallery from '../gallery/Galerija';
import "./Home.scss";

const Home = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 200); // Adjust scroll distance as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    <Navbar />
      <div id="/" className="hero">
        <video
          className="hero-video"
          autoPlay
          loop
          muted
          src="/assets/herovideo.mp4"  // Absolute path from the public directory
          type="video/mp4"
        />
        <div className="hero-content">
          <div className="hero-logo-container">
            <img
              src="/logo512.png"
              alt="Logo"
              className="hero-logo"
            />
          </div>
          <div className="hero-button-container">
          </div>
        </div>
      
      </div>
      <Ponuda />
      <About />
      <Footer />

      </>
  );
};

export default Home;
