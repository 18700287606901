import React, { useEffect, useState } from 'react';
import './Orders.scss'; // Import SCSS if you have styles

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://api.gilje.shop/getOrders');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sortedOrders = data.sort((a, b) => b.id - a.id);
        setOrders(sortedOrders);
      } catch (error) {
        setError('Error fetching orders: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleDelete = async (orderId) => {
    try {
      await fetch(`https://api.gilje.shop/orders/${orderId}`, {
        method: 'DELETE'
      });
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.id !== orderId)
      );
    } catch (error) {
      setError('Error deleting order: ' + error.message);
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      await fetch(`https://api.gilje.shop/orders/${orderId}/status`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status: newStatus })
      });
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
    } catch (error) {
      setError('Error updating order status: ' + error.message);
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case 'New':
        return '#FFC107'; // Yellow
      case 'Contacted':
        return '#007BFF'; // Blue
      case 'Shipped':
        return '#28A745'; // Green
      case 'Archived':
        return '#000'; // Black
      default:
        return '#6c757d'; // Gray for unknown status
    }
  };

  if (loading) return <div>Loading...</div>;

  if (error) return <div>{error}</div>;

  return (
    <div className="orders-container">
      <h2>Orders</h2>
      <table className="orders-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>City</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Items</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td data-label="ID">{order.id}</td>
              <td data-label="First Name">{order.first_name}</td>
              <td data-label="Last Name">{order.last_name}</td>
              <td data-label="City">{order.city}</td>
              <td data-label="Address">{order.address_line1}</td>
              <td data-label="Phone">{order.phone_number}</td>
              <td data-label="Email">{order.email}</td>
              <td data-label="Items">
                {order.cart && order.cart.length > 0
                  ? order.cart.map((item) => (
                      <div key={item.naziv}>
                        <strong>{item.naziv}</strong> - {item.cena}
                      </div>
                    ))
                  : 'No items'}
              </td>
              <td data-label="Status">
                <span
                  className="status-badge"
                  style={{ backgroundColor: getStatusColor(order.status) }}
                >
                  {order.status || 'New'}
                </span>
              </td>
              <td data-label="Actions">
                <select
                  value={order.status || 'New'}
                  onChange={(e) => handleStatusChange(order.id, e.target.value)}
                >
                  <option value="New">New</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Archived">Archived</option>
                </select>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(order.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
