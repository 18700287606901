import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Products.scss'; // Import SCSS for styling

const Products = () => {
  const [products, setProducts] = useState([]);
  const [form, setForm] = useState({
    id: null,
    naziv: '',
    cena: '',
    specifikacija: '',
    opis: '',
    image: ''
  });

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://api.gilje.shop/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  // Handle form submission
  const handleSaveProduct = async (e) => {
    e.preventDefault();
  
    const formData = {
      id: form.id || null,
      naziv: form.naziv,
      cena: parseFloat(form.cena),
      specifikacija: form.specifikacija,
      opis: form.opis,
      image: form.image
    };
  
    try {
      if (form.id) {
        await axios.put(`https://api.gilje.shop/products/${form.id}`, formData);
      } else {
        const response = await axios.post('https://api.gilje.shop/products', formData);
        console.log('New product added:', response.data);
        setProducts([...products, response.data]);
        localStorage.setItem('productsUpdated', Date.now()); // Notify other components
      }
      setForm({ id: null, naziv: '', cena: '', specifikacija: '', opis: '', image: '' });
      fetchProducts();
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  // In Ponuda component
  useEffect(() => {
    const handleStorageChange = () => {
      if (localStorage.getItem('productsUpdated')) {
        fetchProducts();
        localStorage.removeItem('productsUpdated');
      }
    };
  
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Delete product
  const handleDeleteProduct = async (id) => {
    try {
      await axios.delete(`https://api.gilje.shop/products/${id}`);
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Set form for editing a product
  const handleEditProduct = (product) => {
    setForm(product);
  };

  return (
    <div className="product-container">
      <div className="form-container">
        <h2>{form.id ? 'Izmeni Produkt' : 'Kreiraj Produkt'}</h2>
        <form onSubmit={handleSaveProduct}>
          <div className="form-group">
            <label htmlFor="naziv">Naziv:</label>
            <input
              type="text"
              id="naziv"
              name="naziv"
              value={form.naziv}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cena">Cena:</label>
            <input
              type="number"
              id="cena"
              name="cena"
              value={form.cena}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="specifikacija">Specifikacija:</label>
            <input
              type="text"
              id="specifikacija"
              name="specifikacija"
              value={form.specifikacija}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="opis">Opis:</label>
            <textarea
              id="opis"
              name="opis"
              value={form.opis}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image">URL slike:</label>
            <input
              type="text"
              id="image"
              name="image"
              value={form.image}
              onChange={handleInputChange}
            />
            {form.image && (
              <div className="image-preview-container">
                <img
                  src={form.image}
                  alt="Preview"
                  className="image-preview"
                  onClick={() => window.open(form.image, '_blank')}
                />
              </div>
            )}
          </div>
          <button type="submit" className="submit-button">
            {form.id ? 'Sačuvaj Promene' : 'Novi Proizvod'}
          </button>
        </form>
      </div>

      <div className="products-display">
        <h2>Proizvodi</h2>
        <div className="products-list">
          {products.map(product => (
            <div key={product.id} className="product-item">
              {product.image && (
                <img
                  src={product.image}
                  alt={product.naziv} 
                  className="product-image"
                  onClick={() => window.open(product.image, '_blank')}
                />
              )}
              <div className="product-details">
                <h3>{product.naziv}</h3>
                <p><strong>Cena:</strong> {product.cena} RSD</p>
                <p><strong>Specifikacija:</strong> {product.specifikacija}</p>
                <p><strong>Opis:</strong> {product.opis}</p>
              </div>
              <div className="product-actions">
                <button className="edit-button" onClick={() => handleEditProduct(product)}>Izmeni</button>
                <button className="delete-button" onClick={() => handleDeleteProduct(product.id)}>Obriši</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
